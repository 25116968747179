<template>
  <div class="wrapper">
    <vue-editor-toolbar
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :model="mode"
      style="border-bottom: 1px solid #ccc"
    >
    </vue-editor-toolbar>
    <vue-editor
      v-model="content"
      :defaultConfig="editorConfig"
      :model="mode"
      @onCreated="onCreated"
      style="height: 500px; overflow-y: hidden"
    >
    </vue-editor>
    <div>{{ content }}</div>
  </div>
</template>

<script>
import { baseURL } from "@/config";
export default {
  name: "test401",
  data() {
    return {
      editor: null,
      content: "<p>你好</p>",
      toolbarConfig: {},
      editorConfig: {},
      mode: "default",
    };
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor);
      this.editorConfig = this.editor.getConfig();
      this.editorConfig.MENU_CONF['uploadImage']={
        fieldName: 'editor',
        server: baseURL + "/api/base/ueditor/upload",
        allowedFileTypes: ['image/*'],
        timeout: 5 * 1000,
      }
    },
  },
  mounted() {},
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy();
  },
};
</script>

<style lang="scss" scoped>
</style>